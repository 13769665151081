<template>
    <div class="tw-p-2 tw-bg-gray-100 tw-min-h-full tw-h-[calc(100vh-80px)]">
        <div class="tw-h-full tw-w-full tw-bg-white tw-flex tw-items-center tw-justify-center">
            <icon icon="line-md:loading-twotone-loop" class="tw-text-2xl tw-text-primary-500" />
        </div>
    </div>
</template>

<script setup>
</script>

<style>

</style>