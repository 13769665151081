<template>
  <div class="tw-w-full tw-h-screen tw-flex tw-items-center tw-justify-center tw-flex-col">
    
    <router-link class="tw-px-8 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-text-white tw-bg-amber-500" to="/cachier">Cashier</router-link>
    <router-link class="tw-px-8 tw-py-2 tw-border tw-border-solid tw-rounded-md tw-text-white tw-bg-emerald-500 tw-mt-5" to="/dashboard">Dashboard</router-link>
  </div>
</template>

<script setup>
</script>
