<template>
  <div>
    <!-- <button class="tw-p-1 tw-border tw-border-solid tw-border-transparent tw-duration-200 hover:tw-bg-white hover:tw-border-neutral-200 tw-rounded-lg">
        <icon icon="solar:eye-linear" class="tw-text-xl tw-text-blue-500" />
    </button> -->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>